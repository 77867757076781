<template>
  <div class="cont">
    <p class="title">党纪教育</p>
    <div class="list">
      <div class="list_item" v-for="(item, index) in list" :key="index">
        <span></span>
        <p @click="handleClick(item)">{{ item.title }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [{
        title: '党纪学习教育——中央部署',
        url: 'https://www.12371.cn/djxx/zybs/',
        type: 0
      }, {
        title: '和行公司各基层党组织召开党纪学习教育启动部署会',
        url: '',
        type: 1
      }, {
        title: '和行第一党支部党纪学习教育读书班开班',
        url: '',
        type: 2
      }, {
        title: '和瑞公司党支部党纪学习教育读书班开班',
        url: '',
        type: 3
      }, {
        title: '和行第一党支部开展党纪学习教育专题党课',
        url: '',
        type: 4
      }, {
        title: '和瑞公司党支部开展党纪学习教育专题党课',
        url: '',
        type: 5
      }]
    }
  },
  methods: {
    handleClick(item) {
      if (item.url === '') {
        if (item.type === 1) {
          this.$router.push({
            name: 'PartydisciplineStudy'
          })
        } else if (item.type === 2) {
          this.$router.push({
            name: 'PartyOne'
          })
        } else if (item.type === 3) {
          this.$router.push({
            name: 'PartyTwo'
          })
        } else if (item.type === 4) {
          this.$router.push({
            name: 'PartyThree'
          })
        } else if (item.type === 5) {
          this.$router.push({
            name: 'PartyFour'
          })
        }
      } else {
        window.open(item.url)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.cont {
  width: 90%;
  margin-top: 4rem;
  margin-left: 5%;
  background: #fff;

  .title {
    width: 100%;
    font-size: .2rem;
    font-weight: 550;
    padding: .22rem;
    box-sizing: border-box;
    border-bottom: solid .01rem #00000042;
  }

  .list {
    font-size: .18rem;
    color: grey;
    padding: 0 .2rem .5rem;

    .list_item {
      display: flex;
      align-items: center;
      padding: .2rem;
      border-bottom: solid .01rem #00000042;

      span {
        width: .07rem;
        height: .07rem;
        background: grey;
        border-radius: 50%;
        margin-right: .1rem;
      }

      p {
        cursor: pointer;
      }
    }

    .list_item:hover {
      background: rgba(128, 128, 128, 0.075);
      color: rgba(0, 0, 0, 0.699);
    }

    .list_item:last-child {
      border-bottom: none;
    }
  }
}
</style>
