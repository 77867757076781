import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Produce from '../views/Produce.vue'
import Pro from '../views/Pro.vue'
import Serve from '../views/Serve.vue'
import Support from '../views/Support.vue'
import About from '../views/About.vue'
import Contact from '../views/Contact.vue'
import PartyBuilding from '../views/PartyBuilding.vue'
import Study from '../views/Study.vue'
import PartydisciplineStudy from '../views/PartydisciplineStudy.vue'
import PartyOne from '../views/PartyOne.vue'
import PartyTwo from '../views/PartyTwo.vue'
import PartyThree from '../views/PartyThree.vue'
import PartyFour from '../views/PartyFour.vue'

Vue.use(VueRouter)

// 获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
// 修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  }, {
    path: '/produce',
    name: 'Produce',
    component: Produce
  }, {
    path: '/pro',
    name: 'Pro',
    component: Pro
  }, {
    path: '/serve',
    name: 'Serve',
    component: Serve
  }, {
    path: '/support',
    name: 'Support',
    component: Support
  }, {
    path: '/about',
    name: 'About',
    component: About
  }, {
    path: '/contact',
    name: 'Contact',
    component: Contact
  }, {
    path: '/partyBuilding',
    name: 'PartyBuilding',
    component: PartyBuilding
  }, {
    path: '/study',
    name: 'Study',
    component: Study
  }, {
    path: '/PartydisciplineStudy',
    name: 'PartydisciplineStudy',
    component: PartydisciplineStudy
  }, {
    path: '/PartyOne',
    name: 'PartyOne',
    component: PartyOne
  }, {
    path: '/PartyTwo',
    name: 'PartyTwo',
    component: PartyTwo
  }, {
    path: '/PartyThree',
    name: 'PartyThree',
    component: PartyThree
  }, {
    path: '/PartyFour',
    name: 'PartyFour',
    component: PartyFour
  }
]

const router = new VueRouter({
  mode: 'hash',
  routes
})

export default router
