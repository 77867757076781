<template>
  <div class="cont">
    <p class="title">主题教育</p>
    <div class="list">
      <div class="list_item" v-for="(item, index) in list" :key="index">
        <span></span>
        <p @click="handleClick(item.url)">{{ item.title }}</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [{
        title: '学习贯彻习近平新时代中国特色社会主义思想',
        url: 'https://www.12371.cn/special/xxzd/hxnr/'
      }, {
        title: '安徽先锋网-学习贯彻习近平新时代中国特色社会主义主题教育',
        url: 'https://www.ahxf.gov.cn/Zt/shzysx/Index'
      }, {
        title: '习近平在安徽考察时强调',
        url: 'https://tv.cctv.com/2020/08/21/VIDEEqjgK89ztrAfa8DjjUo8200821.shtml'
      }, {
        title: '习近平：健全全面从严治党体系 推动新时代党的建设新的伟大工程向纵深发展',
        url: 'https://www.ahxf.gov.cn/Zt/shzysx/Content?Id=1144442&ClassId=6789'
      }, {
        title: '学习汇编 | 习近平这样阐述“以学铸魂”',
        url: 'https://www.ahxf.gov.cn/Zt/shzysx/Content?Id=1144031&ClassId=6799'
      }, {
        title: '江汽集团公司党委组织领导干部赴省党风廉政教育馆开展主题警示教育',
        url: 'https://info.jac.com.cn/info/ztjy/20230517/2675.html'
      }, {
        title: '江汽集团公司党委召开学习贯彻习近平新时代中国特色社会主义思想主题教育动员部署会',
        url: 'https://info.jac.com.cn/info/ztjy/20230421/2644.html'
      }]
    }
  },
  methods: {
    handleClick(url) {
      window.open(url)
    }
  }
}
</script>

<style lang="less" scoped>
.cont {
  width: 90%;
  margin-top: 4rem;
  margin-left: 5%;
  background: #fff;

  .title {
    width: 100%;
    font-size: .2rem;
    font-weight: 550;
    padding: .22rem;
    box-sizing: border-box;
    border-bottom: solid .01rem #00000042;
  }

  .list {
    font-size: .18rem;
    color: grey;
    padding: 0 .2rem .5rem;

    .list_item {
      display: flex;
      align-items: center;
      padding: .2rem;
      border-bottom: solid .01rem #00000042;

      span {
        width: .07rem;
        height: .07rem;
        background: grey;
        border-radius: 50%;
        margin-right: .1rem;
      }

      p {
        cursor: pointer;
      }
    }

    .list_item:hover {
      background: rgba(128, 128, 128, 0.075);
      color: rgba(0, 0, 0, 0.699);
    }

    .list_item:last-child {
      border-bottom: none;
    }
  }
}
</style>
