<template>
  <div class="cont">
    <p class="title">二十大</p>
    <div class="list">
      <div class="list_item" v-for="(item, index) in list" :key="index">
        <span></span>
        <p @click="handleClick(item.url)">{{ item.title }}</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [{
        title: '习近平在学习贯彻二十大精神研讨班上发表重要讲话',
        url: 'https://www.12371.cn/2023/02/07/ARTI1675772723487442.shtml'
      }, {
        title: '和行党总支书记有关二十大的党课开展',
        url: ''
      }, {
        title: '江汽集团完成学习贯彻党的二十大精神集中轮训工作',
        url: 'https://info.jac.com.cn/info/ershida/20230403/2640.html'
      }, {
        title: '学习党发展全过程人民民主，推进法治中国建设——四论学习贯彻党的二十大精神的二十大精神',
        url: 'http://www.news.cn/politics/2022-10/30/c_1129088765.htm'
      }, {
        title: '肩负起新时代新征程党的使命任务——一论学习贯彻党的二十大精神',
        url: 'http://www.news.cn/politics/cpc20/2022-10/25/c_1129079952.htm'
      }, {
        title: '牢牢把握团结奋斗的时代要求',
        url: 'http://www.news.cn/comments/2022-10/22/c_1129075323.htm'
      }]
    }
  },
  methods: {
    handleClick(url) {
      if (url === '') {
        this.$router.push({
          name: 'Study'
        })
      } else {
        window.open(url)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.cont {
  width: 90%;
  margin-top: 4rem;
  margin-left: 5%;
  background: #fff;

  .title {
    width: 100%;
    font-size: .2rem;
    font-weight: 550;
    padding: .22rem;
    box-sizing: border-box;
    border-bottom: solid .01rem #00000042;
  }

  .list {
    font-size: .18rem;
    color: grey;
    padding: 0 .2rem .5rem;

    .list_item {
      display: flex;
      align-items: center;
      padding: .2rem;
      border-bottom: solid .01rem #00000042;

      span {
        width: .07rem;
        height: .07rem;
        background: grey;
        border-radius: 50%;
        margin-right: .1rem;
      }

      p {
        cursor: pointer;
      }
    }

    .list_item:hover {
      background: rgba(128, 128, 128, 0.075);
      color: rgba(0, 0, 0, 0.699);
    }

    .list_item:last-child {
      border-bottom: none;
    }
  }
}
</style>
