<template>
  <div id="app">
    <!-- 头部 -->
    <header>
      <div class="header_cont">
        <div class="logo">
          <img src="./assets/images/home/logo.png" alt="" />
          <span>和行科技</span>
        </div>
        <ul>
          <li v-for="(item, index) in tabList" :key="index" @click="tabClick(index)">
            <span class="tab_title">{{ item.title }}</span>
            <span v-show="actNum == index" class="bottom_line"></span>
            <div class="second_li">
              <span :class="activeIndex == 0 ? 'active_index' : ''" @click="handleClick(0)">快车</span>
              <span :class="activeIndex == 1 ? 'active_index' : ''" @click="handleClick(1)">公务用车</span>
            </div>
            <div class="fifth_li">
              <span :class="activeIndex == 4 ? 'active_index' : ''" @click="handleClick(4)">党纪教育</span>
              <span :class="activeIndex == 2 ? 'active_index' : ''" @click="handleClick(2)">主题教育</span>
              <span :class="activeIndex == 3 ? 'active_index' : ''" @click="handleClick(3)">二十大</span>
            </div>
          </li>
        </ul>
      </div>
    </header>
    <router-view />
    <!-- footer -->
    <footer>
      <ul>
        <li v-for="(item, index) in footerList" :key="index">
          <span>{{ item.main }}</span>
          <p @click="footerClick(index, 0)">{{ item.sub1 }}</p>
          <p @click="footerClick(index, 1)">{{ item.sub2 }}</p>
        </li>
      </ul>
      <div class="footer_line"></div>
      <div class="footer_word">
        <p>安徽合肥市经开区紫蓬路1325号和行科技有限公司</p>
        <p>Copyright© 2021 合肥和行科技有限公司 All Rights Reserved</p>
        <p>
          <img src="./assets/images/logo.png" alt="" />
          皖公网安备 34019102000893号 皖ICP备18015830号-3
        </p>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
      activeIndex: 0,
      // header的tab栏内容
      tabList: [
        {
          title: '首页'
        },
        {
          title: '产品'
        },
        {
          title: '服务'
        },
        {
          title: '支持'
        },
        {
          title: '企业党建'
        },
        {
          title: '关于'
        },
        {
          title: '联系我们'
        }
      ],
      // tab栏的默认选项
      actNum: 0,
      // footer
      footerList: [
        {
          main: '产品介绍',
          sub1: '快车',
          sub2: '公务用车'
        },
        {
          main: '常见问题',
          sub1: '售前支持',
          sub2: '产品运营'
        },
        {
          main: '关于我们',
          sub1: '公司简介',
          sub2: '企业资源'
        },
        {
          main: '售前咨询',
          sub1: 'hxyc@jac.com.cn',
          sub2: '400-160-8899'
        }
      ]
    }
  },
  created() {
    document.write(
      unescape(
        "%3Cspan style='display:none' id='cnzz_stat_icon_1279796398'%3E%3C/span%3E%3Cscript src='https://s9.cnzz.com/z_stat.php%3Fid%3D1279796398%26show%3Dpic' type='text/javascript'%3E%3C/script%3E"
      )
    )
    if (!sessionStorage.getItem('index')) {
      this.actNum = 0
      this.$router.push('/')
    } else {
      this.actNum = sessionStorage.getItem('index')
    }
    if (sessionStorage.getItem('activeIndex') === null) {
      this.activeIndex = 0
    } else {
      this.activeIndex = sessionStorage.getItem('activeIndex')
    }
  },
  mounted() {
    // 创建vm实例后执行
    // 浏览器控制按钮前进后退触发函数
    window.addEventListener('popstate', this.popstate, false)
  },
  destroyed() {
    // 销毁vm组件
    // 避免堆栈溢出，多次创建、多次触发
    window.removeEventListener('popstate', this.popstate, false)
  },
  methods: {
    // 监听浏览器自带返回按钮
    popstate() {
      var a = window.location.href.substring(window.location.href.lastIndexOf('/'), window.location.href.length)
      if (a === '/') {
        sessionStorage.setItem('index', 0)
        this.actNum = 0
      } else if (a === '/pro' || a === '/produce') {
        sessionStorage.setItem('index', 1)
        this.actNum = 1
      } else if (a === '/serve') {
        sessionStorage.setItem('index', 2)
        this.actNum = 2
      } else if (a === '/support') {
        sessionStorage.setItem('index', 3)
        this.actNum = 3
      } else if (a === '/about') {
        sessionStorage.setItem('index', 4)
        this.actNum = 4
      } else {
        sessionStorage.setItem('index', 5)
        this.actNum = 5
      }
    },
    // 点击tab栏触发事件
    tabClick(index) {
      this.actNum = index
      sessionStorage.setItem('index', String(index))
      if (index !== 1 && index !== 4) {
        this.activeIndex = 0
        sessionStorage.removeItem('activeIndex')
      }
      if (index === 0) {
        this.$router.push('/')
        sessionStorage.removeItem('supportNum')
      } else if (index === 2) {
        this.$router.push('/serve')
        sessionStorage.removeItem('supportNum')
      } else if (index === 3) {
        this.$router.push('/support')
      } else if (index === 5) {
        this.$router.push('/about')
        sessionStorage.removeItem('supportNum')
      } else if (index === 6) {
        this.$router.push('/contact')
        sessionStorage.removeItem('supportNum')
      }
    },
    // 点击快车和公务用车选项触发事件
    handleClick(activeIndex) {
      this.activeIndex = activeIndex
      sessionStorage.setItem('activeIndex', String(activeIndex))
      if (activeIndex === 0) {
        if (sessionStorage.getItem('activeItem') !== 0) {
          this.$router.push('/produce')
        }
      } else if (activeIndex === 1) {
        if (sessionStorage.getItem('activeItem') !== 1) {
          this.$router.push('/pro')
        }
      } else if (activeIndex === 2) {
        if (sessionStorage.getItem('activeItem') !== 2) {
          this.$router.push('/partyBuilding?item=themeducation')
        }
      } else if (activeIndex === 3) {
        if (sessionStorage.getItem('activeItem') !== 3) {
          this.$router.push('/partyBuilding?item=twenty')
        }
      } else if (activeIndex === 4) {
        if (sessionStorage.getItem('activeItem') !== 4) {
          this.$router.push('/partyBuilding?item=partydiscipline')
        }
      }
    },
    // 点击底部选项触发事件
    footerClick(index, e) {
      if (index === 0) {
        sessionStorage.setItem('index', 1)
        this.actNum = 1
        if (e === 0) {
          this.$router.push('/produce')
        } else {
          this.$router.push('/pro')
        }
      } else if (index === 1) {
        sessionStorage.setItem('supportNum', e)
        sessionStorage.setItem('index', 3)
        this.actNum = 3
        this.$router.push('/support')
        // 回到顶部
        document.documentElement.scrollTop = document.body.scrollTop = 0
        setTimeout(() => {
          location.reload()
        }, 200)
      } else if (index === 2) {
        sessionStorage.setItem('index', 4)
        this.actNum = 4
        this.$router.push('/about')
        document.documentElement.scrollTop = document.body.scrollTop = 0
      }
    }
  }
}
</script>

<style lang="less" scoped>
#app {
  // filter: progid:DXImageTransform.Microsoft.BasicImage(graysale=1);
  // -webkit-filter: grayscale(100%);
  overflow: hidden;
  width: 100%;
  height: auto;
  min-width: 1080px;
  display: flex;
  flex-direction: column;
  font-family: 'Microsoft YaHei', 'Segoe UI', 'Lucida Grande', 'STHeiti Light', Helvetica, Arial, FreeSans, Arimo, 'Droid Sans', 'wenquanyi micro hei', 'Hiragino Sans GB', 'Hiragino Sans GB W3',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;

  // 头部
  header {
    display: flex;
    justify-content: center;
    width: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: 999;
    position: absolute;

    .header_cont {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 67%;
      height: 0.74rem;

      .logo {
        display: flex;
        align-items: center;

        img {
          display: block;
          margin-right: 0.04rem;
          width: 0.32rem;
          height: 0.32rem;
        }

        span {
          color: #fefefe;
          font-size: 0.3rem;
          font-weight: bold;
        }
      }

      ul {
        display: flex;
        color: #fefefe;
        font-size: 0.16rem;
        height: 100%;

        li {
          display: flex;
          align-items: center;
          flex-direction: column;
          cursor: pointer;
          transition-duration: 0.3s;

          .tab_title {
            padding: 0 0.46rem;
            flex: 1;
            line-height: 0.7rem;
          }

          .bottom_line {
            width: 0.32rem;
            height: 0.04rem;
            background: #fa2800;
            justify-self: flex-end;
          }

          .second_li,
          .fifth_li {
            width: 100%;
            display: none;
            z-index: 999;
            margin-top: 0.04rem;
            background: rgba(0, 0, 0, 0.7) !important;

            span {
              color: #999999;
              width: 100%;
              text-align: center;
              padding: 0.2rem;
              transition-duration: 0.3s;
            }

            span:nth-child(1) {
              border-bottom: solid 1px rgba(255, 255, 255, 0.3);
            }

            .active_index {
              color: #ffffff;
            }

            span:hover {
              background: rgba(255, 255, 255, 0.7);
              color: #000;
            }
          }

          @media screen and(-ms-high-contrast:active),
          (-ms-high-contrast: none) {

            .second_li,
            .fifth_li {
              margin-top: 0.74rem;
            }
          }
        }

        li:hover {
          background: rgba(255, 255, 255, 0.7);
          color: #000;
        }

        li:nth-child(2):hover,
        li:nth-child(5):hover {
          background: transparent;
          color: #fefefe;
        }

        li:nth-child(2):hover .second_li,
        li:nth-child(5):hover .fifth_li {
          display: flex;
          flex-direction: column;
          cursor: pointer;
        }

        li:nth-child(2):hover,
        li:nth-child(5):hover {
          cursor: default;
        }
      }
    }
  }

  // footer
  footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #1f2739;
    width: 100%;

    ul {
      display: flex;
      justify-content: space-between;
      width: 67%;

      li {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0.81rem 0.1rem 0.48rem;

        span {
          color: #ffffff;
          font-size: 0.18rem;
          margin-bottom: 0.07rem;
        }

        p {
          display: inline-block;
          transform: scale(0.9);
          color: #b3b3b3;
          font-size: 0.14rem;
          margin-top: 0.24rem;
          cursor: pointer;
        }
      }

      li:last-child {
        p {
          cursor: default;
          color: #ffffff;
          font-weight: bold;
        }
      }
    }

    .footer_line {
      width: 67%;
      height: 0.01rem;
      background: #323b4d;
    }

    .footer_word {
      width: 100%;
      display: flex;
      justify-content: center;
      margin: 0.8rem 0 0.4rem;

      p {
        display: flex;
        align-items: center;
        color: #7b7e86;
        font-size: 0.15rem;
        transform: scale(0.85);

        img {
          width: 0.22rem;
          margin-right: 0.05rem;
        }
      }

      p:nth-child(2) {
        margin: 0 0.11rem;
      }
    }
  }
}
</style>
