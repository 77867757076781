<template>
  <div class="pro">
    <!-- banner -->
    <div class="banner">
      <div class="banner_introduce">
        <span>公务用车</span>
        <p>员工出行，企业支付无需报销和垫付</p>
        <p>实时记录，一键企业支付，公务出行更简单</p>
        <img @click="handleClick" src="../assets/images/pro/btn.png" alt="" />
      </div>
    </div>
    <!-- first -->
    <div class="first cont">
      <div class="left">
        <span>随时随地便捷出行</span>
        <span
          >员工无需繁琐的贴票报销
          <img class="block1" src="../assets/images/pro/block1.png" alt="" />
        </span>
        <p class="p1">满足加班回家，商务差旅等多种出行场景</p>
        <p class="p2">不垫钱，不贴票，免报销，让员工回归轻松，专注工作本身</p>
      </div>
      <img class="right" src="../assets/images/pro/first.png" alt="" />
    </div>
    <!-- second -->
    <div class="second">
      <div class="second_cont">
        <span
          >多场景覆盖企业用车
          <img src="../assets/images/pro/block2.png" alt="" />
        </span>
        <div class="img">
          <img class="img1" src="../assets/images/pro/second1.png" alt="" />
          <img class="img2" src="../assets/images/pro/second2.png" alt="" />
        </div>
      </div>
    </div>
    <!-- third -->
    <div class="third cont">
      <img class="right" src="../assets/images/pro/third.png" alt="" />
      <div class="left">
        <span>报销流程全面简化</span>
        <span
          >财务效率有效提升
          <img class="block3" src="../assets/images/pro/block3.png" alt="" />
        </span>
        <p class="p1">企业支付帮助大幅精简繁冗的财务流程</p>
        <p class="p2">有效提升费用报销、结算等管理效率</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    handleClick() {
      this.$router.push('/contact')
      sessionStorage.setItem('index', 5)
      setTimeout(() => {
        location.reload()
      }, 10)
    }
  }
}
</script>

<style lang="less" scoped>
.pro {
  display: flex;
  flex-direction: column;
  align-items: center;
  .banner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: url(../assets/images/pro/banner.png);
    background-size: 100% 100%;
    padding: 1.4rem 0 0.82rem;
    .banner_introduce {
      width: 67%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      span {
        font-size: 0.48rem;
        color: #ffffff;
        font-weight: bold;
        margin-bottom: 0.4rem;
      }
      p {
        font-size: 0.3rem;
        color: #ffffff;
      }
      p:nth-child(2) {
        margin-bottom: 0.3rem;
      }
      img {
        width: 1.69rem;
        height: 0.49rem;
        margin-top: 0.51rem;
        cursor: pointer;
      }
    }
  }
  .first {
    margin: 1.26rem 0 1.08rem;
    .right {
      width: 4.98rem;
      height: 5.46rem;
    }
    .left {
      align-items: flex-start;
      span {
        img {
          width: 4.02rem;
          right: -0.1rem;
        }
      }
    }
  }
  .third {
    margin: 0.88rem 0 1.28rem;
    .right {
      width: 6.15rem;
      height: 5.64rem;
    }
    .left {
      align-items: center;
      span {
        img {
          width: 3.57rem;
          right: -0.1rem;
        }
      }
    }
  }
  .cont {
    display: flex;
    width: 67%;
    justify-content: space-between;
    align-items: center;
    .left {
      display: flex;
      flex-direction: column;
      color: #303133;
      span {
        position: relative;
        font-size: 0.42rem;
        font-weight: bold;
        img {
          height: 0.32rem;
          bottom: -0.12rem;
          position: absolute;
          z-index: -1;
        }
      }
      p {
        font-size: 0.24rem;
      }
      .p1 {
        margin-top: 1.01rem;
      }
      span:nth-child(2),
      .p2 {
        margin-top: 0.3rem;
      }
    }
  }
  .second {
    width: 100%;
    display: flex;
    justify-content: center;
    background: #eef3f8;
    z-index: -2;
    .second_cont {
      width: 80%;
      display: flex;
      flex-direction: column;
      align-items: center;
      span {
        color: #303133;
        font-size: 0.42rem;
        font-weight: bold;
        position: relative;
        margin-top: 0.84rem;
        img {
          width: 2.45rem;
          height: 0.32rem;
          position: absolute;
          bottom: -0.12rem;
          left: -0.1rem;
          z-index: -1;
        }
      }
      .img {
        display: flex;
        margin: 0.96rem 0 1.21rem;
        img {
          height: 3.58rem;
          margin: 0 0.175rem;
        }
        .img1 {
          width: 7.066rem;
        }
        .img2 {
          width: 6.35rem;
        }
      }
    }
  }
}
</style>
