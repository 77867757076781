<template>
  <!-- container -->
  <div class="container">
    <!-- banner -->
    <div class="banner">
      <swiper class="swiper_new" :options="swiperOption">
        <swiper-slide v-for="(item, index) in swiper" :key="index">
          <div class="advantage-swiper_img"
            :style="{ width: '100%', height: '100%', backgroundImage: 'url(' + item.icon + ')', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center center' }">
          </div>
        </swiper-slide>
      </swiper>
      <div class="swiper-pagination"></div>
      <div class="banner_btn" :style="displayStyle">
        <div class="banner_main_title" v-text="bannerTitle"></div>
        <img @click="handleClick" src="../assets/images/home/btn.png" alt="" />
      </div>
    </div>
    <!-- cont -->
    <div class="cont">
      <!-- 智能化系统服务平台 -->
      <div class="cont_part cont_first">
        <div class="cont_part_title cont_first_title">
          <p class="main_title">
            智能化系统服务平台
            <img src="../assets/images/home/rectangle4.png" alt="" />
          </p>
          <p class="sub_title">多年技术累计与实践，智能化运营和管理</p>
        </div>
        <ul class="cont_first_tab">
          <li v-for="(item, index) in contFirstTabList" :key="index" @click="firstTabClick(index)">
            <img :src="item.icon" alt="" />
            <span>{{ item.title }}</span>
            <span v-show="firstActNum === index" class="active"></span>
          </li>
        </ul>
        <div class="cont_first_container">
          <div class="cont_first_box">
            <div class="introduce">
              <b>{{ firstTitle }}</b>
              <span>{{ firstTip }}</span>
            </div>
            <img :src="firstSrc" alt="" />
          </div>
        </div>
      </div>
      <!-- 全闭环生态化解决方案 -->
      <div class="cont_part cont_second">
        <div class="cont_part_title cont_second_title">
          <p class="main_title">
            全闭环生态化解决方案
            <img src="../assets/images/home/rectangle3.png" alt="" />
          </p>
          <p class="sub_title">构建从多场景的用车需求到运力平台支撑的出行生态循环</p>
        </div>
        <div class="cont_second_container">
          <div class="introduce">
            <div v-for="(item, index) in contSecondList" :key="index">
              <span>{{ item.main }}</span>
              <p>{{ item.sub }}</p>
            </div>
          </div>
          <img src="../assets/images/home/operate.png" alt="" />
        </div>
      </div>
      <!-- 完善的安全风控体系 -->
      <div class="cont_part cont_third">
        <div class="cont_part_title cont_third_title">
          <p class="main_title">
            完善的安全风控体系
            <img src="../assets/images/home/rectangle2.png" alt="" />
          </p>
          <p class="sub_title">提供从司机源头筛选到行后保障的全业务链路监控</p>
        </div>
        <img class="cont_third_line" src="../assets/images/home/line.png" alt="" />
        <ul class="cont_third_tab">
          <li>源头保障</li>
          <li>行前预防</li>
          <li>行中护航</li>
          <li>行后保障</li>
        </ul>
        <ul class="cont_third_container">
          <li>
            <div class="li_cont" v-for="(item, index) in list1" :key="index">
              <img :src="item.icon" alt="" />
              <div>
                <span>{{ item.main }}</span>
                <p>{{ item.sub }}</p>
              </div>
            </div>
          </li>
          <li>
            <div class="li_cont" v-for="(item, index) in list2" :key="index">
              <img :src="item.icon" alt="" />
              <div>
                <span>{{ item.main }}</span>
                <p>{{ item.sub }}</p>
              </div>
            </div>
          </li>
          <li>
            <div class="li_cont" v-for="(item, index) in list3" :key="index">
              <img :src="item.icon" alt="" />
              <div>
                <span>{{ item.main }}</span>
                <p>{{ item.sub }}</p>
              </div>
            </div>
          </li>
          <li>
            <div class="li_cont" v-for="(item, index) in list4" :key="index">
              <img :src="item.icon" alt="" />
              <div>
                <span>{{ item.main }}</span>
                <p>{{ item.sub }}</p>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <!-- 平台资质达国家网约车标准 -->
      <div class="cont_part cont_four">
        <div class="cont_part_title cont_four_title">
          <p class="main_title">
            平台资质达国家网约车标准
            <img src="../assets/images/home/rectangle1.png" alt="" />
          </p>
          <p class="sub_title">基于深厚的行业和技术积累，和行约车通过国家标准衡量</p>
        </div>
        <div class="cont_four_container">
          <div>
            <img v-for="(item, index) in imgList" :key="index" :src="item.img" alt="" />
          </div>
          <p>*以上为部分证书展示</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data() {
    const vm = this
    return {
      displayStyle: { display: 'none' },
      swiperOption: {
        pagination: '.swiper-pagination',
        paginationClickable: true,
        loop: true,
        speed: 1000,
        autoplay: 3000,
        autoplayDisableOnInteraction: false,
        onTransitionStart: function (swiper) {
          if (swiper.activeIndex === 2) {
            setTimeout(() => {
              vm.$set(vm.displayStyle, 'display', 'block')
            }, 300)
          } else {
            vm.$set(vm.displayStyle, 'display', 'none')
          }
        }
      },
      swiper: [
        {
          icon: require('../assets/images/home/home4.jpg'),
          index: 1
        },
        {
          icon: require('../assets/images/home/banner.png'),
          index: 2
        }
      ],
      // banner大标题
      bannerTitle: '让出行服务更简单',
      // cont_first的tab栏
      contFirstTabList: [
        {
          icon: require('../assets/images/home/icon1.png'),
          title: '资产管理'
        },
        {
          icon: require('../assets/images/home/icon2.png'),
          title: '订单管理'
        },
        {
          icon: require('../assets/images/home/icon3.png'),
          title: '客服管理'
        },
        {
          icon: require('../assets/images/home/icon4.png'),
          title: '数据分析'
        },
        {
          icon: require('../assets/images/home/icon5.png'),
          title: '营销管理'
        },
        {
          icon: require('../assets/images/home/icon6.png'),
          title: '财务管理'
        }
      ],
      firstActNum: 0,
      firstTitle: '资产管理',
      firstTip: '提供司机管理、车辆管理、司机考核等多项效能提升工具。',
      firstSrc: require('../assets/images/cHome/pic1.png'),
      // cont_second的介绍
      contSecondList: [
        {
          main: '完善的系统能力',
          sub: '管理平台、司机端、乘客端、数据分析平台'
        },
        {
          main: '专业的运营体系',
          sub: '从入驻到落地，全程提供专业运营支持'
        },
        {
          main: '多渠道的用车订单',
          sub: '接入多个渠道的订单流量渠道，增加海量订单'
        }
      ],
      // cont_third
      list1: [{ icon: require('../assets/images/home/icon7.png'), main: '人脸识别', sub: '保证人车匹配' }],
      list2: [
        { icon: require('../assets/images/home/icon8.png'), main: '安心小号', sub: '司乘手机号隐私保护通话' },
        { icon: require('../assets/images/home/icon9.png'), main: '行程分享', sub: '分享行程，好友相伴更安心' },
        { icon: require('../assets/images/home/icon10.png'), main: '安全联系人', sub: '任何情况一键通知亲友' }
      ],
      list3: [
        { icon: require('../assets/images/home/icon11.png'), main: '一键报警', sub: '免拨号一键报警' },
        { icon: require('../assets/images/home/icon12.png'), main: '行程录音', sub: '维护司乘安全' }
      ],
      list4: [{ icon: require('../assets/images/home/icon13.png'), main: '司机评价', sub: '司机服务及口碑评价' }],
      // cont_four
      imgList: [
        {
          img: require('../assets/images/homeFour/1.png')
        },
        {
          img: require('../assets/images/homeFour/2.png')
        },
        {
          img: require('../assets/images/homeFour/3.png')
        },
        {
          img: require('../assets/images/homeFour/4.png')
        },
        {
          img: require('../assets/images/homeFour/5.png')
        },
        {
          img: require('../assets/images/homeFour/6.png')
        }
      ]
    }
  },
  methods: {
    handleClick() {
      this.$router.push('/contact')
      sessionStorage.setItem('index', 5)
      setTimeout(() => {
        location.reload()
      }, 10)
    },
    firstTabClick(index) {
      this.firstActNum = index
      if (index === 0) {
        this.firstTitle = '资产管理'
        this.firstTip = '提供司机管理、车辆管理、司机考核等多项效能提升工具。'
        this.firstSrc = require('../assets/images/cHome/pic1.png')
      } else if (index === 1) {
        this.firstTitle = '订单管理'
        this.firstTip = '汇聚贯穿订单全流程的订单核心信息，全面多维度展示。'
        this.firstSrc = require('../assets/images/cHome/pic2.png')
      } else if (index === 2) {
        this.firstTitle = '客服管理'
        this.firstTip = '提供平台化、数据化、智能化的客服服务，辅助人工及数据决策。'
        this.firstSrc = require('../assets/images/cHome/pic3.png')
      } else if (index === 3) {
        this.firstTitle = '数据分析'
        this.firstTip = '涵盖用户、订单、交易、服务效率等方面的多维度数据呈现、分析，运营状况一目了然。'
        this.firstSrc = require('../assets/images/cHome/pic4.png')
      } else if (index === 4) {
        this.firstTitle = '营销管理'
        this.firstTip = '全面丰富的营销能力，更好地提升司机活跃度，从而实现经营等指标的有效提升。'
        this.firstSrc = require('../assets/images/cHome/pic5.png')
      } else {
        this.firstTitle = '财务管理'
        this.firstTip = '财务列表多维度呈现，财务核算即时完成，账目清晰明了，提升财务人员的日常工作效能。'
        this.firstSrc = require('../assets/images/cHome/pic6.png')
      }
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep .swiper-pagination .swiper-pagination-bullet {
  background: #000000;
  margin: 0 5px;
  opacity: .3;
}

::v-deep .swiper-pagination .swiper-pagination-bullet-active {
  background: #fff;
  opacity: 1;
}

.container {
  display: flex;
  flex-direction: column;

  .banner {
    position: relative;

    .swiper_new {
      width: 100%;
      height: 7.8rem;
    }

    .swiper-pagination {
      z-index: 99999999;
      position: absolute;
      bottom: 3%;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      justify-content: center;
    }

    .banner_btn {
      position: absolute;
      z-index: 99999999;
      bottom: 30%;
      left: 50%;
      transform: translateX(-50%);

      .banner_main_title {
        color: #fefefe;
        font-size: 0.72rem;
        font-weight: bold;
        letter-spacing: 0.1rem;
      }

      img {
        width: 1.69rem;
        height: 0.49rem;
        margin: 0.76rem auto 0;
        cursor: pointer;
      }
    }
  }

  // cont
  .cont {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .cont_part {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding: 0.84rem 0 0;

      .cont_part_title {
        display: flex;
        flex-direction: column;
        align-items: center;

        .main_title {
          color: #303133;
          font-size: 0.42rem;
          position: relative;
          font-weight: bold;

          img {
            position: absolute;
            bottom: -0.1rem;
            z-index: -1;
          }
        }

        .sub_title {
          color: #303133;
          font-size: 0.24rem;
          margin-top: 0.36rem;
        }
      }
    }

    // 智能化系统服务平台
    .cont_first {
      .cont_first_title {
        .main_title {
          img {
            left: -0.06rem;
            width: 2.32rem;
            height: 0.32rem;
          }
        }
      }

      .cont_first_tab {
        width: 80%;
        display: flex;
        justify-content: center;
        padding: 0.3rem 0 0;

        li {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 0 0.58rem;
          cursor: pointer;

          img {
            width: 1rem;
            margin-bottom: 0.2rem;
          }

          span {
            color: #303133;
            font-size: 0.24rem;
            font-weight: bold;
          }

          .active {
            width: 0;
            height: 0;
            border-width: 0 0.2rem 0.2rem;
            border-style: solid;
            border-color: transparent transparent #24293a;
            margin-top: 0.25rem;
          }
        }
      }

      .cont_first_container {
        width: 100%;
        background: #1f2739;
        display: flex;
        justify-content: center;

        .cont_first_box {
          display: flex;
          align-items: center;
          justify-content: space-between;
          // width: 80%;
          height: 4.4rem;

          .introduce {
            display: flex;
            flex-direction: column;
            color: #ffffff;
            width: 4rem;
            margin-right: 1.57rem;

            b {
              font-size: 0.48rem;
            }

            span {
              white-space: normal;
              font-size: 0.18rem;
              line-height: 0.4rem;
              margin-top: 0.36rem;
            }
          }

          img {
            width: 6.57rem;
            align-self: flex-end;
          }
        }
      }
    }

    // 全闭环生态化解决方案
    .cont_second {
      margin-bottom: 0.29rem;

      .cont_second_title {
        .main_title {
          img {
            width: 2.77rem;
            height: 0.32rem;
            left: -0.06rem;
          }
        }
      }

      .cont_second_container {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .introduce {
          display: flex;
          flex-direction: column;

          div {
            display: flex;
            flex-direction: column;
            color: #303133;
            margin: 0.345rem;

            span {
              font-size: 0.3rem;
              font-weight: bold;
            }

            p {
              font-size: 0.18rem;
              margin-top: 0.19rem;
            }
          }
        }

        img {
          width: 4.91rem;
          height: 5.78rem;
          margin-left: 3.7rem;
        }
      }
    }

    // 完善的安全风控体系
    .cont_third {
      background: url(../assets/images/home/banner2.png);
      background-size: 100% 100%;
      z-index: -2;

      .cont_third_title {
        .main_title {
          color: #ffffff;

          img {
            right: -0.06rem;
            width: 2.77rem;
            height: 0.32rem;
          }
        }

        .sub_title {
          color: #b3b3b3;
        }
      }

      .cont_third_line {
        width: 11.23rem;
        margin: 0.54rem 0 0.33rem;
      }

      .cont_third_tab {
        width: 12.15rem;
        display: flex;
        color: #ffffff;
        font-size: 0.3rem;
        justify-content: space-between;
        font-weight: bold;
      }

      .cont_third_container {
        display: flex;
        justify-content: space-between;

        li {
          width: 3.14rem;
          background: rgba(0, 0, 0, 0.75);
          margin: 0.5rem 0.25rem;
          padding: 0 0.4rem;

          .li_cont {
            display: flex;
            margin: 0.37rem 0;
            align-items: flex-start;

            img {
              height: 0.5rem;
              margin-right: 0.14rem;
            }

            div {
              display: flex;
              flex-direction: column;
              align-items: flex-start;

              span {
                color: #fefefe;
                font-size: 0.24rem;
                font-weight: bold;
              }

              p {
                color: #b3b3b3;
                font-size: 0.18rem;
                white-space: normal;
                margin-top: 0.14rem;
              }
            }
          }
        }
      }
    }

    // 平台资质达国家网约车标准
    .cont_four {
      width: 80%;

      .cont_four_title {
        .main_title {
          img {
            right: -0.06rem;
            width: 3.22rem;
            height: 0.32rem;
          }
        }
      }

      .cont_four_container {
        // width: 100%;
        display: flex;
        flex-direction: column;

        div {
          display: flex;
          justify-content: space-between;

          img {
            margin: 1.11rem 0.095rem 0;
            width: 2.028rem;
            height: 2.734rem;
          }
        }

        p {
          color: #303133;
          font-size: 0.18rem;
          margin: 0.61rem 0 1.29rem;
          align-self: flex-end;
        }
      }
    }
  }

  @media screen and(-ms-high-contrast:active),
  (-ms-high-contrast: none) {
    .banner {
      .banner_title {
        top: 0;
      }
    }
  }
}
</style>
