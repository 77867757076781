<template>
  <div class="produce">
    <!-- banner -->
    <div class="banner">
      <div class="banner_introduce">
        <span>快车</span>
        <p>实时定位，快速呼叫，一键支付，安全出行，</p>
        <p>满足乘客不同出行需求</p>
        <img @click="handleClick" src="../assets/images/home/btn.png" alt="" />
      </div>
    </div>
    <!-- 司机端 -->
    <div class="dp d">
      <div class="dp_introduce">
        <span
          >司机端
          <img src="../assets/images/produce/block1.png" alt="" />
        </span>
        <ul class="dp_ul">
          <li class="d_li" v-for="(item, index) in driverList" :key="index">{{ item.text }}</li>
        </ul>
      </div>
      <div class="dp_img d_img">
        <div class="dp_img_top">
          <img src="../assets/images/produce/left.png" class="left" @click="dLeftClick" alt="" />
          <ul class="dp_img_ul">
            <li v-for="(item, index) in dImg" :key="index"><img :src="item.img" alt="" /></li>
          </ul>
          <img src="../assets/images/produce/right.png" class="right" @click="dRightClick" alt="" />
        </div>
        <div class="dp_img_bottom">
          <span class="d_active" :style="{ left: dDistance + '%' }"></span>
        </div>
      </div>
    </div>
    <!-- 乘客端 -->
    <div class="dp p">
      <div class="dp_img p_img">
        <div class="dp_img_top">
          <img src="../assets/images/produce/left.png" class="left" @click="pLeftClick" alt="" />
          <ul class="dp_img_ul">
            <li v-for="(item, index) in pImg" :key="index"><img :src="item.img" alt="" /></li>
          </ul>
          <img src="../assets/images/produce/right.png" class="right" @click="pRightClick" alt="" />
        </div>
        <div class="dp_img_bottom">
          <span class="p_active" :style="{ left: pDistance + '%' }"></span>
        </div>
      </div>
      <div class="dp_introduce">
        <span
          >乘客端
          <img src="../assets/images/produce/block2.png" alt="" />
        </span>
        <ul class="dp_ul">
          <li class="p_li" v-for="(item, index) in passengerList" :key="index">{{ item.text }}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      driverList: [
        {
          text: '动态接单'
        },
        {
          text: '地图导航'
        },
        {
          text: '服务考核'
        },
        {
          text: '司乘同显'
        },
        {
          text: '客服申诉'
        },
        {
          text: '营销活动'
        },
        {
          text: '排行榜'
        },
        {
          text: '订单热力图'
        }
      ],
      passengerList: [
        {
          text: '司乘同显'
        },
        {
          text: '费用预估'
        },
        {
          text: '安全监控'
        },
        {
          text: '实名认证'
        },
        {
          text: '一键登录'
        },
        {
          text: '在线支付'
        },
        {
          text: '行后评价'
        },
        {
          text: '行程分享'
        }
      ],
      dImg: [
        {
          img: require('../assets/images/dpImg/d1.png')
        },
        {
          img: require('../assets/images/dpImg/d2.png')
        },
        {
          img: require('../assets/images/dpImg/d3.png')
        },
        {
          img: require('../assets/images/dpImg/d4.png')
        },
        {
          img: require('../assets/images/dpImg/d5.png')
        },
        {
          img: require('../assets/images/dpImg/d6.png')
        },
        {
          img: require('../assets/images/dpImg/d7.png')
        }
      ],
      pImg: [
        {
          img: require('../assets/images/dpImg/p1.png')
        },
        {
          img: require('../assets/images/dpImg/p2.png')
        },
        {
          img: require('../assets/images/dpImg/p3.png')
        },
        {
          img: require('../assets/images/dpImg/p4.png')
        },
        {
          img: require('../assets/images/dpImg/p5.png')
        },
        {
          img: require('../assets/images/dpImg/p6.png')
        },
        {
          img: require('../assets/images/dpImg/p7.png')
        }
      ],
      dDistance: 0,
      pDistance: 0
    }
  },
  methods: {
    handleClick() {
      this.$router.push('/contact')
      sessionStorage.setItem('index', 5)
      setTimeout(() => {
        location.reload()
      }, 10)
    },
    dLeftClick() {
      var item = this.dImg[this.dImg.length - 1]
      this.dImg.unshift(item)
      this.dImg.pop()
      if (this.dDistance <= 0) {
        this.dDistance = 85.7
      } else {
        this.dDistance = this.dDistance - 14.3
      }
    },
    dRightClick() {
      var item = this.dImg[0]
      this.dImg.push(item)
      this.dImg.splice(0, 1)
      if (this.dDistance >= 85.7) {
        this.dDistance = 0
      } else {
        this.dDistance = this.dDistance + 14.3
      }
    },
    pLeftClick() {
      var item = this.pImg[this.pImg.length - 1]
      this.pImg.unshift(item)
      this.pImg.pop()
      if (this.pDistance <= 0) {
        this.pDistance = 85.7
      } else {
        this.pDistance = this.pDistance - 14.3
      }
    },
    pRightClick() {
      var item = this.pImg[0]
      this.pImg.push(item)
      this.pImg.splice(0, 1)
      if (this.pDistance >= 85.7) {
        this.pDistance = 0
      } else {
        this.pDistance = this.pDistance + 14.3
      }
    }
  }
}
</script>

<style lang="less" scoped>
.produce {
  display: flex;
  flex-direction: column;
  align-items: center;
  // banner
  .banner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: url(../assets/images/produce/banner.png);
    background-size: 100% 100%;
    padding: 1.4rem 0 0.82rem;
    .banner_introduce {
      width: 67%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      span {
        font-size: 0.48rem;
        color: #ffffff;
        font-weight: bold;
        margin-bottom: 0.4rem;
      }
      p {
        font-size: 0.3rem;
        color: #ffffff;
      }
      p:nth-child(2) {
        margin-bottom: 0.3rem;
      }
      img {
        width: 1.69rem;
        height: 0.49rem;
        margin-top: 0.51rem;
        cursor: pointer;
      }
    }
  }
  .d {
    border-bottom: dashed 1px #000;
  }
  .dp {
    width: 67%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .dp_introduce {
      display: flex;
      flex-direction: column;
      span {
        color: #303133;
        font-size: 0.4rem;
        font-weight: bold;
        position: relative;
        img {
          width: 1.35rem;
          height: 0.32rem;
          position: absolute;
          left: 0;
          bottom: -0.12rem;
          z-index: -1;
        }
      }
      .dp_ul {
        display: flex;
        margin-top: 0.47rem;
        width: 5.84rem;
        flex-wrap: wrap;
        li {
          width: 1.3rem;
          height: 0.44rem;
          border-radius: 0.22rem;
          margin: 0.24rem 0.16rem 0 0;
          color: #ffffff;
          font-size: 0.22rem;
          text-align: center;
          line-height: 0.44rem;
        }
        .d_li {
          background: #5d9eff;
        }
        .p_li {
          background: #fa2800;
        }
      }
    }
    .dp_img {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 1.43rem 0 0.81rem;
      .dp_img_top {
        display: flex;
        align-items: center;
        position: relative;
        .left,
        .right {
          width: 0.32rem;
          height: 0.32rem;
          cursor: pointer;
          position: absolute;
        }
        .left {
          left: 0.4rem;
        }
        .right {
          right: 0.4rem;
        }
        .dp_img_ul {
          display: flex;
          li {
            width: 2.41rem;
            height: 5.04rem;
            display: none;
            img {
              width: 100%;
              height: 100%;
            }
          }
          li:nth-child(3) {
            display: block;
            transform: translateX(40%) scale(0.85);
          }
          li:nth-child(5) {
            display: block;
            transform: translateX(-40%) scale(0.85);
          }
          li:nth-child(4) {
            display: block;
            z-index: 999;
          }
        }
      }
      .dp_img_bottom {
        background: #f5f5f5;
        width: 4rem;
        height: 0.1rem;
        margin-top: 0.52rem;
        border-radius: 0.05rem;
        position: relative;
        span {
          display: block;
          width: 0.57rem;
          height: 100%;
          border-radius: 0.05rem;
          position: absolute;
          transition-duration: 0.5s;
        }
        .d_active {
          background: #5d9eff;
        }
        .p_active {
          background: #fa2800;
        }
      }
    }
    .p_img {
      margin-left: -0.4rem;
    }
  }
  .p {
    .dp_introduce {
      margin-right: -0.16rem;
    }
  }
}
</style>
