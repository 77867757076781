<template>
  <div class="serve">
    <div class="banner">
      <div class="banner_title">
        <p>服务标准流程</p>
        <span>SERVICE</span>
      </div>
    </div>
    <div class="cont">
      <div class="container">
        <img src="../assets/images/serve/img1.png" alt="" />
        <img src="../assets/images/serve/img3.png" alt="" />
        <img src="../assets/images/serve/img5.png" alt="" />
        <img src="../assets/images/serve/img7.png" alt="" />
        <img src="../assets/images/serve/img9.png" alt="" />
        <img src="../assets/images/serve/img2.png" alt="" />
        <img src="../assets/images/serve/img4.png" alt="" />
        <img src="../assets/images/serve/img6.png" alt="" />
        <img src="../assets/images/serve/img8.png" alt="" />
      </div>
      <ul class="card">
        <li>
          <img src="../assets/images/serve/icon1.png" alt="" />
          <span>咨询服务</span>
          <div class="line"></div>
          <p>解决方案咨询，我方可以提供关于组织、模式上的调整方案和落地方案。</p>
        </li>
        <li>
          <img src="../assets/images/serve/icon2.png" alt="" />
          <span>培训服务</span>
          <div class="line"></div>
          <p>完成系统研发后，我司会针对整套系统对合作伙伴进行系统培训。</p>
        </li>
        <li>
          <img src="../assets/images/serve/icon3.png" alt="" />
          <span>驻场服务</span>
          <div class="line"></div>
          <p>从需求对接到项目交付，我们都会到现场与您充分沟通，以便快速解决您的问题。</p>
        </li>
      </ul>
    </div>
    <div class="btn">
      <div class="btn_box"  @click="handleClick">
        点击查询
        <span></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    handleClick() {
      this.$router.push('/contact')
      sessionStorage.setItem('index', 5)
      setTimeout(() => {
        location.reload()
      }, 10)
    }
  }
}
</script>

<style lang="less" scoped>
.serve {
  display: flex;
  flex-direction: column;
  width: 100%;
  .banner {
    width: 100%;
    display: flex;
    justify-content: center;
    background: url(../assets/images/serve/banner.png);
    background-size: 100% 100%;
    color: #2d3032;
    .banner_title {
      width: 67%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin: 1.96rem 0 2.33rem;
      p {
        font-size: 0.16rem;
      }
      span {
        font-size: 0.48rem;
        font-weight: bold;
        margin-top: 0.12rem;
      }
    }
  }
  .cont {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 9.06rem;
    background: #f2f2f2;
    .container {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      width: 67%;
      margin: 0.8rem 0;
      img {
        width: 2.29rem;
        height: 2.64rem;
        margin: 0 0.1rem;
      }
      img:nth-child(6),
      img:nth-child(7),
      img:nth-child(8),
      img:nth-child(9) {
        margin-top: -0.5rem;
      }
      img:hover {
        transform: scale(1.1);
        transition-duration: 0.3s;
      }
    }
    .card {
      width: 13rem;
      height: 5.12rem;
      display: flex;
      justify-content: space-between;
      background: #ffffff;
      padding: 1.32rem 0.62rem;
      box-shadow: 0 0.05rem 0.05rem 0 rgba(0, 0, 0, 0.18);
      li {
        width: 3.27rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        img {
          width: 0.84rem;
          height: 0.84rem;
        }
        span {
          font-size: 0.3rem;
          color: #303133;
        }
        .line {
          width: 0.76rem;
          height: 0.08rem;
          background: #fa2800;
        }
        p {
          white-space: normal;
          color: #24293a;
          font-size: 0.18rem;
          line-height: 0.35rem;
        }
      }
    }
  }
  .btn {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 5.96rem;
    .btn_box {
      margin-top: 4.06rem;
      width: 1.8rem;
      height: 0.4rem;
      border: solid 1px #666666;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #666666;
      font-size: 0.14rem;
      cursor: pointer;
      span {
        display: block;
        width: 0;
        height: 0;
        border-width: 0.06rem;
        border-style: solid;
        border-color: transparent transparent transparent #666666;
        margin-left: 0.12rem;
      }
    }
    .btn_box:hover {
      background: #fa2800;
      color: #ffffff;
      border-color: #ffffff;
      span {
        border-color: transparent transparent transparent #ffffff;
      }
    }
  }
  @media screen and(-ms-high-contrast:active), (-ms-high-contrast: none) {
    /*兼容IE11*/
    .cont {
      position: relative;
      .card {
        transform: translateX(-50%);
        position: absolute;
        li {
          p {
            width: 100%;
            word-wrap: break-word;
          }
        }
      }
    }
  }
}
</style>
