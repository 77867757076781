import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'

// 配置请求的根路径
axios.defaults.baseURL = 'https://api.hexingyueche.com/website/h5/contact/'
axios.interceptors.request.use(config => {
  return config
})
axios.interceptors.response.use(config => {
  return config
})
Vue.use(VueAwesomeSwiper /* { default global options } */)
Vue.prototype.$axios = axios

Vue.config.productionTip = false

router.afterEach((to, from, next) => {
  window.scrollTo(0, 0)
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
