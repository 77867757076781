var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"support"},[_vm._m(0),_c('div',{staticClass:"cont"},[_c('div',{staticClass:"title_tab"},[_vm._m(1),_c('div',{staticClass:"tab"},[_c('span',{class:_vm.supportNum == 0 ? 'active' : '',on:{"click":function($event){return _vm.handleClick(0)}}},[_vm._v("售前支持")]),_c('span',[_vm._v(" | ")]),_c('span',{class:_vm.supportNum == 1 ? 'active' : '',on:{"click":function($event){return _vm.handleClick(1)}}},[_vm._v("产品运营")])])]),(_vm.supportNum == 0)?_c('ul',{staticClass:"container"},[_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6)]):_c('ul',{staticClass:"container box"},[_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11)]),_c('div',{staticClass:"btn",on:{"click":_vm.contactClick}},[_vm._v(" 点击查询 "),_c('span')])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner"},[_c('div',{staticClass:"banner_title"},[_c('p',[_vm._v("业务支持")]),_c('span',[_c('b',[_vm._v("SUPPORT")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_vm._v("业务支持 "),_c('b',[_vm._v("Support")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('img',{attrs:{"src":require("../assets/images/support/icon1.png"),"alt":""}}),_c('div',[_c('span',[_vm._v("系统是否可以支持城际，出租，顺风车等业务？")]),_c('p',[_vm._v("目前系统支持快车、公务用车业务类型，其他业务类型还在陆续开通中")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('img',{attrs:{"src":require("../assets/images/support/icon2.png"),"alt":""}}),_c('div',[_c('span',[_vm._v("平台运营经验和系统操作经验较欠缺，可以启动运营吗？")]),_c('p',[_vm._v("在您上线前将有一对一培训，为您解答系统及业务相关知识；上线后我们提供全方位的培训及运营支持，同时，我们也将为您安排经验丰富的运营经理在合作期间提供全流程支持")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('img',{attrs:{"src":require("../assets/images/support/icon3.png"),"alt":""}}),_c('div',[_c('span',[_vm._v("合作后平台都可以收到哪些软件服务？")]),_c('p',[_vm._v("和行科技将会给到您定制化司机端接单软件、平台后台管理系统；另外，如果您有自己的流量渠道，我们还会额外向您交付乘客端下单软件")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('img',{attrs:{"src":require("../assets/images/support/icon4.png"),"alt":""}}),_c('div',[_c('span',[_vm._v("司机端都支持哪些设备和型号的手机？")]),_c('p',[_vm._v("司机端支持安卓及iOS（苹果）两个版本，同时司机端软件在合作期间会随着业务发展不断迭代升级")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('img',{attrs:{"src":require("../assets/images/support/icon5.png"),"alt":""}}),_c('div',[_c('span',[_vm._v("合作和上线流程大概是什么样的？")]),_c('p',[_vm._v("合作前意向及需求洽谈 > 双方确认合作方案 > 凭条交付所需材料及信息 > 和行科技复核信息并进行项目排期 > 项目对接及上线")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('img',{attrs:{"src":require("../assets/images/support/icon6.png"),"alt":""}}),_c('div',[_c('span',[_vm._v("和行科技可以协助对接哪些流量渠道呢？")]),_c('p',[_vm._v("目前主流的网约车流量渠道如高德、美团、滴滴等均可协助对接，您可根据当地市场情况开通优势渠道运营")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('img',{attrs:{"src":require("../assets/images/support/icon7.png"),"alt":""}}),_c('div',[_c('span',[_vm._v("系统都有哪些模块？都支持哪些岗位的人员使用？")]),_c('p',[_vm._v("目前系统包含乘客管理、订单管理、财务管理、营销中心等几大模块，支持不同角色的企业人员使用相应模块开展工作")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('img',{attrs:{"src":require("../assets/images/support/icon8.png"),"alt":""}}),_c('div',[_c('span',[_vm._v("和行科技还会提供哪些服务支持？")]),_c('p',[_vm._v("除了基础的软件服务外，还提供可视化数据分析、发票系统等支持，为您的业务提效赋能，您可根据业务需要选择不同的服务")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('img',{attrs:{"src":require("../assets/images/support/icon9.png"),"alt":""}}),_c('div',[_c('span',[_vm._v("是否可提供业务及策略类相关咨询服务？")]),_c('p',[_vm._v("和行科技有30人的策略智囊团为您的业务推进出谋划策，让您无忧前行")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('img',{attrs:{"src":require("../assets/images/support/icon10.png"),"alt":""}}),_c('div',[_c('span',[_vm._v("司机如何注册平台？")]),_c('p',[_vm._v("目前可支持平台导入司机信息、发布司机招募链接邀请司机注册、新用户司机端注册等多种方式供司机进行注册")])])])
}]

export { render, staticRenderFns }