<template>
  <div class="content">
    <party-1 v-if="$route.query.item === 'themeducation'"></party-1>
    <party-2 v-else-if="$route.query.item === 'twenty'"></party-2>
    <party-3 v-else></party-3>
  </div>
</template>
<script>
import party1 from './components/party1.vue'
import party2 from './components/party2.vue'
import party3 from './components/party3.vue'

export default {
  components: {
    party1, party2, party3
  }
}
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  min-height: 70vh;
  max-height: 150vh;
  padding-bottom: .5rem;
  background-image: url('../assets/images/home/home5.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}
</style>
