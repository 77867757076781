<template>
  <div class="contact">
    <div class="banner">
      <div class="banner_title">
        <p>联系我们</p>
        <span><b>CONTACT</b> US</span>
        <p>邮箱：hxyc@jac.com.cn</p>
      </div>
    </div>
    <div class="cont">
      <div class="card">
        <span>留下您的联系方式</span>
        <p>专业顾问会马上联系您</p>
        <ul class="info">
          <li>
            <input type="text" v-model="companyName" placeholder="请输入企业名称（必填）" @blur="companyNameCheck" />
          </li>
          <li>
            <input type="text" v-model="name" placeholder="请输入您的尊称（必填）" @blur="nameCheck" />
          </li>
          <li>
            <input type="text" v-model="mobile" placeholder="请输入您的手机号（必填）" @blur="mobileCheck" />
          </li>
          <li>
            <input type="text" v-model="code" placeholder="请输入您的验证码（必填）" @blur="codeCheck" />
            <span @click="getCode" :style="{ 'pointer-events': clickFlag, color: colorFlag }">{{ text }}</span>
          </li>
        </ul>
        <div class="advice">
          <div class="advice_left">
            <p>请输入您的业务需求（必填，不少于5个字）</p>
            <div>
              <input type="text" v-model="advice" @input="adviceInput" @blur="adviceCheck" />
              <span>{{ adviceLength }}/200</span>
            </div>
          </div>
          <div class="btn" @click="handleClick">提交</div>
        </div>
      </div>
    </div>
    <div class="toast" v-show="visible === true">{{ toast }}</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      companyName: '',
      name: '',
      mobile: '',
      code: '',
      advice: '',
      toast: '你好',
      visible: false,
      adviceLength: 0,
      text: '获取验证码',
      clickFlag: 'auto',
      colorFlag: '#ef6262',
      reg: /[^\u0020-\u007E\u00A0-\u00BE\u2E80-\uA4CF\uF900-\uFAFF\uFE30-\uFE4F\uFF00-\uFFEF\u0080-\u009F\u2000-\u201f\u2026\u2022\u20ac\r\n]/g,
      item: {
        companyName: false,
        name: false,
        mobile: false,
        code: false,
        advice: false
      }
    }
  },
  created() {
    if (sessionStorage.getItem('toast')) {
      this.toast = sessionStorage.getItem('info')
      this.visible = true
      setTimeout(() => {
        this.visible = false
        sessionStorage.removeItem('toast')
        sessionStorage.removeItem('info')
      }, 2000)
    }
  },
  methods: {
    // 获取验证码
    getCode() {
      if (this.item.mobile === true) {
        this.$axios
          .get(`code?mobile=${this.mobile}`)
          .then(data => {
            console.log(data)
            if (data.data.returnCode !== 10000) {
              this.toast = data.data.msg
              this.getToast()
            } else {
              var time = 60
              var timer = null
              clearInterval(timer)
              timer = setInterval(() => {
                time--
                this.text = time + 's'
                this.clickFlag = 'none'
                this.colorFlag = '#666666'
                if (time === 0) {
                  time = 60
                  clearInterval(timer)
                  this.text = '获取验证码'
                  this.clickFlag = 'auto'
                  this.colorFlag = '#ef6262'
                }
              }, 1000)
            }
          })
          .catch(function(error) {
            console.log(error)
          })
      } else {
        this.mobileCheck()
      }
    },
    // 提交信息
    handleClick() {
      if (this.item.companyName === true && this.item.name === true && this.item.mobile === true && this.item.code === true && this.item.advice === true) {
        this.$axios
          .get(
            `message?companyName=${encodeURIComponent(this.companyName)}&contactName=${encodeURIComponent(this.name)}&contactPhone=${this.mobile}&code=${encodeURIComponent(
              this.code
            )}&businessContent=${encodeURIComponent(this.advice)}`
          )
          .then(data => {
            if (data.data.returnCode === 10000) {
              sessionStorage.setItem('toast', true)
              sessionStorage.setItem('info', '感谢您的咨询，我们会尽快与您联系')
              // 回到顶部
              location.reload()
              document.documentElement.scrollTop = document.body.scrollTop = 0
            } else {
              this.toast = data.data.msg
              this.getToast()
            }
          })
          .catch(function(error) {
            console.log(error)
          })
      } else {
        if (this.item.companyName === false) {
          this.companyNameCheck()
        } else if (this.item.name === false) {
          this.nameCheck()
        } else if (this.item.mobile === false) {
          this.mobileCheck()
        } else if (this.item.code === false) {
          this.codeCheck()
        } else {
          this.adviceCheck()
        }
      }
    },
    // 企业名称验证
    companyNameCheck() {
      if (this.companyName === '') {
        this.item.companyName = false
        this.toast = '请输入企业名称'
        this.getToast()
      } else if (this.companyName.length > 25) {
        this.item.companyName = false
        this.toast = '您输入的企业名称内不得超过25个字符'
        this.getToast()
      } else if (this.name.match(this.reg)) {
        this.item.companyName = false
        this.toast = '您输入的企业名称内不得包含表情包'
        this.getToast()
      } else {
        this.item.companyName = true
      }
    },
    // 姓名验证
    nameCheck() {
      if (this.name === '') {
        this.item.name = false
        this.toast = '请输入您的尊称'
        this.getToast()
      } else if (this.name.match(this.reg)) {
        this.item.name = false
        this.toast = '您输入的内容中不得包含表情包'
        this.getToast()
      } else if (this.name.length > 15) {
        this.item.name = false
        this.toast = '您输入的姓名不得超过15个字符'
        this.getToast()
      } else {
        this.item.name = true
      }
    },
    // 手机号验证
    mobileCheck() {
      if (this.mobile === '') {
        this.item.mobile = false
        this.toast = '您的手机号为空'
        this.getToast()
      } else if (this.mobile.length !== 11 && this.mobile.length !== 0) {
        this.item.mobile = false
        this.toast = '您的手机号格式不正确'
        this.getToast()
      } else if (!/^1[3-9]\d{9}$/.test(this.mobile)) {
        this.item.mobile = false
        this.toast = '您的手机号格式不正确'
        this.getToast()
      } else {
        this.item.mobile = true
      }
    },
    // 验证码校验
    codeCheck() {
      if (this.code === '') {
        this.item.code = false
        this.toast = '请输入验证码'
        this.getToast()
      } else {
        this.item.code = true
      }
    },
    // 输入需求校验
    adviceInput() {
      this.adviceLength = this.advice.length
      if (this.advice.length > 200) {
        this.advice = this.advice.slice(0, 200)
        this.adviceLength = 200
      }
    },
    adviceCheck() {
      if (this.advice === '') {
        this.item.advice = false
        this.toast = '请填写您的业务需求'
        this.getToast()
      } else if (this.adviceLength < 5) {
        this.item.advice = false
        this.toast = '业务需求不少于5个字'
        this.getToast()
      } else if (this.advice.match(this.reg)) {
        this.item.advice = false
        this.toast = '您输入的内容中不得包含表情包'
        this.getToast()
      } else {
        this.item.advice = true
      }
    },
    // 弹窗提示
    getToast() {
      this.visible = true
      setTimeout(() => {
        this.visible = false
      }, 3000)
    }
  }
}
</script>

<style lang="less" scoped>
.contact {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  .banner {
    width: 100%;
    background: url(../assets/images/contact/banner.png);
    background-size: 100% 100%;
    .banner_title {
      display: flex;
      flex-direction: column;
      width: 67%;
      margin: 0 auto;
      color: #2d3032;
      padding: 2.25rem 0 1.95rem;
      p {
        font-size: 0.16rem;
      }
      span {
        font-size: 0.4rem;
        margin: 0.11rem 0 0.18rem;
        b {
          color: #ef6262;
        }
      }
    }
  }
  .cont {
    display: flex;
    justify-content: center;
    width: 100%;
    background: #f8f8f8;
    .card {
      padding: 0.4rem;
      box-shadow: 0 0.05rem 0.05rem 0 rgba(38, 38, 38, 0.06);
      display: flex;
      flex-direction: column;
      width: 67%;
      min-width: 820px;
      margin-top: -0.4rem;
      margin-bottom: 1rem;
      background: #ffffff;
      span {
        color: #242627;
        font-size: 0.24rem;
        font-weight: bold;
      }
      p {
        color: #2d3032;
        font-size: 0.16rem;
        margin: 0.15rem 0 0.4rem;
      }
      .info {
        display: flex;
        margin-bottom: 0.56rem;
        li {
          flex: 1;
          margin-right: 0.4rem;
          font-size: 0.14rem;
          border-bottom: solid 1px #999999;
          input {
            background: none;
            outline: none;
            border: none;
            height: 0.5rem;
          }
          span {
            font-size: 0.14rem;
            font-weight: normal;
            cursor: pointer;
          }
        }
        li:last-child {
          margin-right: 0;
        }
        @media screen and(-ms-high-contrast:active), (-ms-high-contrast: none) {
          li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            input {
              flex: 1;
            }
          }
        }
      }
      .advice {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        .advice_left {
          flex: 1;
          display: flex;
          flex-direction: column;
          p {
            font-size: 0.14rem;
            color: #666666;
            margin: 0;
          }
          div {
            display: flex;
            align-items: center;
            border-bottom: solid 1px #999999;
            input {
              width: 100%;
              background: none;
              outline: none;
              border: none;
              height: 0.5rem;
            }
            span {
              font-size: 0.14rem;
              font-weight: normal;
              color: #9b9b9b;
            }
          }
        }
        .btn {
          display: flex;
          justify-content: center;
          align-items: center;
          border: solid 1px #999999;
          font-size: 0.14rem;
          width: 1.5rem;
          height: 0.4rem;
          margin-left: 1.33rem;
          cursor: pointer;
        }
        .btn:hover {
          color: #ffffff;
          background: #fa2800;
          border-color: #ffffff;
        }
      }
    }
  }
  .toast {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.7);
    padding: 0.2rem 0.3rem;
    color: #ffffff;
    font-size: 0.24rem;
    border-radius: 0.1rem;
  }
}
</style>
