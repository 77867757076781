<template>
  <div class="support">
    <div class="banner">
      <div class="banner_title">
        <p>业务支持</p>
        <span><b>SUPPORT</b></span>
      </div>
    </div>
    <div class="cont">
      <div class="title_tab">
        <div class="title">业务支持 <b>Support</b></div>
        <div class="tab">
          <span :class="supportNum == 0 ? 'active' : ''" @click="handleClick(0)">售前支持</span>
          <span> | </span>
          <span :class="supportNum == 1 ? 'active' : ''" @click="handleClick(1)">产品运营</span>
        </div>
      </div>
      <ul v-if="supportNum == 0" class="container">
        <li>
          <img src="../assets/images/support/icon1.png" alt="" />
          <div>
            <span>系统是否可以支持城际，出租，顺风车等业务？</span>
            <p>目前系统支持快车、公务用车业务类型，其他业务类型还在陆续开通中</p>
          </div>
        </li>
        <li>
          <img src="../assets/images/support/icon2.png" alt="" />
          <div>
            <span>平台运营经验和系统操作经验较欠缺，可以启动运营吗？</span>
            <p>在您上线前将有一对一培训，为您解答系统及业务相关知识；上线后我们提供全方位的培训及运营支持，同时，我们也将为您安排经验丰富的运营经理在合作期间提供全流程支持</p>
          </div>
        </li>
        <li>
          <img src="../assets/images/support/icon3.png" alt="" />
          <div>
            <span>合作后平台都可以收到哪些软件服务？</span>
            <p>和行科技将会给到您定制化司机端接单软件、平台后台管理系统；另外，如果您有自己的流量渠道，我们还会额外向您交付乘客端下单软件</p>
          </div>
        </li>
        <li>
          <img src="../assets/images/support/icon4.png" alt="" />
          <div>
            <span>司机端都支持哪些设备和型号的手机？</span>
            <p>司机端支持安卓及iOS（苹果）两个版本，同时司机端软件在合作期间会随着业务发展不断迭代升级</p>
          </div>
        </li>
        <li>
          <img src="../assets/images/support/icon5.png" alt="" />
          <div>
            <span>合作和上线流程大概是什么样的？</span>
            <p>合作前意向及需求洽谈 > 双方确认合作方案 > 凭条交付所需材料及信息 > 和行科技复核信息并进行项目排期 > 项目对接及上线</p>
          </div>
        </li>
      </ul>
      <ul v-else class="container box">
        <li>
          <img src="../assets/images/support/icon6.png" alt="" />
          <div>
            <span>和行科技可以协助对接哪些流量渠道呢？</span>
            <p>目前主流的网约车流量渠道如高德、美团、滴滴等均可协助对接，您可根据当地市场情况开通优势渠道运营</p>
          </div>
        </li>
        <li>
          <img src="../assets/images/support/icon7.png" alt="" />
          <div>
            <span>系统都有哪些模块？都支持哪些岗位的人员使用？</span>
            <p>目前系统包含乘客管理、订单管理、财务管理、营销中心等几大模块，支持不同角色的企业人员使用相应模块开展工作</p>
          </div>
        </li>
        <li>
          <img src="../assets/images/support/icon8.png" alt="" />
          <div>
            <span>和行科技还会提供哪些服务支持？</span>
            <p>除了基础的软件服务外，还提供可视化数据分析、发票系统等支持，为您的业务提效赋能，您可根据业务需要选择不同的服务</p>
          </div>
        </li>
        <li>
          <img src="../assets/images/support/icon9.png" alt="" />
          <div>
            <span>是否可提供业务及策略类相关咨询服务？</span>
            <p>和行科技有30人的策略智囊团为您的业务推进出谋划策，让您无忧前行</p>
          </div>
        </li>
        <li>
          <img src="../assets/images/support/icon10.png" alt="" />
          <div>
            <span>司机如何注册平台？</span>
            <p>目前可支持平台导入司机信息、发布司机招募链接邀请司机注册、新用户司机端注册等多种方式供司机进行注册</p>
          </div>
        </li>
      </ul>
      <div class="btn" @click="contactClick">
        点击查询
        <span></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      supportNum: 0
    }
  },
  created() {
    if (!sessionStorage.getItem('supportNum')) {
      this.supportNum = 0
    } else {
      this.supportNum = sessionStorage.getItem('supportNum')
    }
  },
  methods: {
    handleClick(supportNum) {
      sessionStorage.setItem('supportNum', supportNum)
      this.supportNum = supportNum
    },
    contactClick() {
      this.$router.push('/contact')
      sessionStorage.setItem('index', 5)
      setTimeout(() => {
        location.reload()
      }, 10)
    }
  }
}
</script>

<style lang="less" scoped>
.support {
  display: flex;
  flex-direction: column;
  align-items: center;
  .banner {
    width: 100%;
    background: url(../assets/images/support/banner.png);
    background-size: 100% 100%;
    .banner_title {
      display: flex;
      flex-direction: column;
      width: 67%;
      margin: 0 auto;
      padding: 2.19rem 0 2.09rem;
      color: #18191b;
      p {
        font-size: 0.16rem;
      }
      span {
        font-size: 0.48rem;
        margin-top: 0.12rem;
      }
    }
  }
  .cont {
    display: flex;
    flex-direction: column;
    width: 67%;
    padding: 1.73rem 0 2.46rem;
    .title_tab {
      display: flex;
      justify-content: space-between;
      .title {
        color: #333333;
        font-size: 0.2rem;
        b {
          color: #9a9a9a;
        }
      }
      .tab {
        color: #979797;
        font-size: 0.18rem;
        .active {
          color: #ef6262;
        }
        span:hover {
          cursor: pointer;
        }
        span:nth-child(1) {
          margin-right: 0.1rem;
        }
        span:nth-child(3) {
          margin-left: 0.1rem;
        }
      }
    }
    .container {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 0.19rem;
      li {
        display: flex;
        align-items: center;
        height: 2.2rem;
        width: 49%;
        border: solid 1px #e6e6e6;
        padding: 0 0.71rem 0 0.49rem;
        margin-top: 0.39rem;
        img {
          margin-right: 0.53rem;
        }
        div {
          width: 100%;
          display: flex;
          flex-direction: column;
          span {
            color: #333333;
            font-size: 0.2rem;
            white-space: normal;
            line-height: 0.24rem;
          }
          p {
            color: #979797;
            font-size: 0.16rem;
            white-space: normal;
            margin-top: 0.24rem;
            line-height: 0.24rem;
          }
        }
      }
      li:hover {
        background: rgba(238, 238, 238, 0.3);
      }
      li:nth-child(1) {
        img {
          height: 0.39rem;
        }
      }
      li:nth-child(2) {
        img {
          height: 0.44rem;
        }
      }
      li:nth-child(3) {
        img {
          height: 0.38rem;
        }
      }
      li:nth-child(4) {
        img {
          height: 0.45rem;
        }
      }
      li:nth-child(5) {
        img {
          height: 0.43rem;
        }
      }
    }
    .box {
      li:nth-child(4) {
        img {
          height: 0.52rem;
        }
      }
    }
    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      border: solid 1px #666666;
      width: 1.8rem;
      height: 0.4rem;
      color: #666666;
      font-size: 0.14rem;
      margin: 0.6rem auto 0;
      cursor: pointer;
      span {
        display: block;
        width: 0;
        height: 0;
        border-width: 0.06rem;
        border-style: solid;
        border-color: transparent transparent transparent #666666;
        margin-left: 0.12rem;
      }
    }
    .btn:hover {
      color: #fff;
      background: #fa2800;
      border-color: white;
      span {
        border-color: transparent transparent transparent #ffffff;
      }
    }
  }
  @media screen and(-ms-high-contrast:active), (-ms-high-contrast: none) {
    /*兼容IE11*/
    .cont {
      .container {
        li {
          div {
            p {
              width: 100%;
              word-wrap: break-word;
            }
          }
        }
      }
    }
  }
}
</style>
